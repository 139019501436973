
export const Environment = {
    production: true,
    redirectUri: () => `${window.location.origin}/`,
    clientId: '3238616c-9842-406d-abe6-055be67236d9',
    authority: 'https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9',
    scopes: ["api://948a3288-79ba-4892-9a7b-db512243aea9/access-as-user"],
    managementHIPUri: 'https://ase-p-app-10001236-hip-mgmt-dev.azurewebsites.net', 
    esbUri:'',  
    piiLoggingEnabled: true, // log personal and organizational data
    appTitle: 'API Catalog - Development - HIP 2.0',
    environment: 'dev'
  };
  